import React, { useState, createContext, useEffect, useCallback, useMemo } from "react";
import Conf from 'Conf';
import { io } from "socket.io-client";
import axios from 'axios';
import {
  useNavigate,useSearchParams
} from "react-router-dom";
import Data from 'Data';
import _ from 'lodash';
import { DateTime,Interval } from 'luxon';

export const ModelContext = createContext({});
const socket = io(Conf.wsUrl);

const ModelProvider = ({children})=>{
  const [searchParams, setSearchParams] = useSearchParams();
  const [ appState,setAppState ] = useState({
    user:{},
    playgroundId:Data.universe.playgrounds[0].id,
    status:'wait',
    rank:0,
  });
  const navigate=useNavigate();
  const [ sid,setSid ] = useState(null);
  const { city,key } = appState;
  const [ presses, setPresses ] = useState(false);
  const setTimer = useState(false)[1];
  useEffect(()=>{
    const handleConnect=() => {
      console.log(socket.id);
      setSid(socket.id);
      socket.io.engine.on("upgrade", () => {
        console.log('transport',socket.io.engine.transport.name);
      });
    };
    const handleDisconnect=() => {
      console.log(socket.id);
      setSid(null);
      setAppState(state=>{return{...state,key:null,status:'fired'}});
    };
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    return ()=>{
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    }
  },[setSid]);
  const post=useCallback((route,payload,cb=()=>{},responseType='json')=>{
    axios.post(Conf.apiUrl+route, {...payload, sid, city}, {responseType}).then((res)=>{
      console.log(res.data);
      cb(res.data.res);
    }).catch((error)=>{
      console.log(error);
    });
  },[sid,city]);
  useEffect(()=>{
    const handleMessage=(msg) => {
      console.log(msg);
      if (msg.status) {
        setAppState(state=>{return  {
          ...state,
          status:msg.status,
        }});
        if (msg.status==='fired') {
          console.log('session expired');
          navigate(`/${city}/`);
        }
      }
      if (msg.rank) {
        setAppState(state=>{return  {
          ...state,
          rank:msg.rank,
        }});
      }
    };
    socket.on("message", handleMessage);
    return ()=>{
      socket.off("message", handleMessage);
    }
  },[setAppState,city,navigate]);
  useEffect(()=>{
    if(key && city && sid && sid!==null) {
      console.log('ready to talk',city,sid);
      setAppState(state=>{return{...state,status:'wait'}});
      post('mobile/hello',{key},(res)=>{
        if (!res.user) setAppState(state=>{return{...state,status:'fired'}});
      });
    }
  },[city,key,sid,post,setAppState]);
  const valid=useMemo(()=>{
    const { user,city }=appState;
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    const ZIPCODE_REGEX = /^\d{5}$/;
    const legalAge=Data.input.legalAge[city]||18;
    const now=DateTime.now();
    const birthDate=user.birthDate || null;
    const interval = Interval.fromDateTimes(birthDate||now, now);
    const age=interval.length('years');
    const errors=[];
    if (!user.country) errors.push(Data.input.errors.country());
    if (city==='la' && !ZIPCODE_REGEX.test(user.zipCode)) errors.push(Data.input.errors.zipCode());
    if (age<legalAge) errors.push(Data.input.errors.age(legalAge));
    if (!user.name) errors.push(Data.input.errors.name());
    if (city==='la' && !user.lastName) errors.push(Data.input.errors.lastName());
    if (!EMAIL_REGEX.test(user.email)) errors.push(Data.input.errors.email());
    if (!user.terms) errors.push(Data.input.errors.terms());
    if (!user.optin) errors.push(Data.input.errors.optin());
    return errors;
  },[appState]);
  useEffect(()=>{
    const resetTimer=()=>{
      if (sid) post('mobile/reset-timer',{});
    }
    const throttled = _.throttle(resetTimer, 5000);
    window.addEventListener('pointerdown',throttled);
    window.addEventListener('keydown',throttled);
    return ()=>{
      window.removeEventListener('pointerdown',throttled);
      window.removeEventListener('keydown',throttled);
    }
  },[sid,post])
  useEffect(()=>{
    const handleKeyDown=(e)=>{
      if (e.which===17) {
        console.log('handleKeyDown');
        setPresses((presses)=>{
          return presses+1;
        });
        setTimer((timer)=>{
          clearInterval(timer);
          return setTimeout(()=>{
            setPresses(0);
          },500);
        });
      }
    }
    window.addEventListener('keydown',handleKeyDown);
    return ()=>{
      window.removeEventListener('keydown',handleKeyDown);
    }
  },[setPresses,setTimer]);
  useEffect(()=>{
    if(presses>2) {
      setAppState(state=>{
        return {
          ...state,
          user:{
            ...state.user,
            country:'FR',
            zipCode:'81120',
            birthDate:DateTime.fromISO('2000-01-01'),
            name:'Woody',
            lastName:'Woody',
            email:'fabrice.lapeyrere@gmail.com',
            terms:true,
            optin:true,
          }
        }
      });
      setPresses(0);
    }
  },[presses,setPresses,setAppState]);
  useEffect(()=>{
    const urlKey=searchParams.get('key');
    if (urlKey) {
      console.log(urlKey);
      setAppState(state=>{return {
        ...state,
        key:urlKey,
      }})
      setSearchParams({});
    } else {
      if (!key) {
        console.log('missing key');
        setAppState(state=>{return{...state,status:'fired'}});
      }
    }
  },[key, searchParams, setSearchParams, setAppState]);
  useEffect(()=>{
    if (['la','ist','dub','ma'].indexOf(city)!==-1) {
      setAppState(state=>{return {
        ...state,
        user:{
          ...state.user,
          country:city==='ma' ? 'CN' : (city==='la'?'US':(city==='dub'?'AE':'TR')),
        },
      }})
    }
  },[city, setAppState]);
  return (
        <ModelContext.Provider value={{appState,setAppState,sid,socket,post,valid}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
