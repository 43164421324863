import React from 'react';
import './index.scss';
import App from './App';
import ModelProvider from "providers/ModelProvider";
import SettingsProvider from "providers/SettingsProvider";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import Utils from 'Utils/Utils';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<div className="mask">
  <BrowserRouter>
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-US">
        <SettingsProvider>
          <ModelProvider>
            <ThemeProvider theme={Utils.theme}>
              <App />
            </ThemeProvider>
          </ModelProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </React.StrictMode>
  </BrowserRouter>
</div>);
