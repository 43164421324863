import React, {useContext, useState, useRef, useEffect} from "react";
import "./Input.scss";
import { ModelContext } from "providers/ModelProvider";
import {
  Link,
} from "react-router-dom";
import Data from 'Data';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import {countries} from 'country-list-json';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

function Input() {
  const { appState,setAppState,valid } = useContext(ModelContext);
  const [ errors,setErrors ]=useState(false);
  const [ optinMore,setOptinMore ]=useState(false);
  const errorsTimer=useRef(null);
  const handleChange=(k,v)=>{
    setAppState(state=>{
      return {
        ...state,
        user:{
          ...state.user,
          [k]:v,
        }
      }
    })
  }
  const { user,city } = appState;
  const showErrors=()=>{
    if (errorsTimer.current) clearTimeout(errorsTimer.current);
    setErrors(true);
    errorsTimer.current=setTimeout(()=>setErrors(false),3000);
  }
  useEffect(()=>{
    return ()=>clearTimeout(errorsTimer.current);
  },[]);
  return <div className="input">
    <div className="logo-small">
      {Data.home.logo}
    </div>
    <div className="form">
      <FormControl fullWidth size="small">
        <InputLabel id="country">{Data.input.country}</InputLabel>
        <Select
          native
          labelId="country"
          value={user.country||''}
          label={Data.input.country}
          onChange={(e)=>handleChange('country',e.target.value)}
        >
          <option aria-label="None" value="" />
          {countries.map((o)=><option key={o.code} value={o.code}>{o.name}</option>)}
        </Select>
      </FormControl>
      <div className="spacer"/>
      {city==='la' && <>
        <TextField fullWidth size="small"
        label={Data.input.zipCode}
        value={user.zipCode||''}
        onChange={(e)=>handleChange('zipCode',e.target.value)}
        />
        <div className="spacer"/>
      </>}
      <MobileDatePicker
        openTo="year"
        label={Data.input.birthDate}
        disableFuture
        inputFormat="dd/LL/yyyy"
        value={user.birthDate||null}
        onChange={(v)=>handleChange('birthDate',v)}
        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
      />
      <div className="spacer"/>
      <TextField fullWidth size="small"
      label={Data.input.name}
      value={user.name||''}
      onChange={(e)=>handleChange('name',e.target.value)}
      />
      <div className="spacer"/>
      {city==='la' && <>
        <TextField fullWidth size="small"
        label={Data.input.lastName}
        value={user.lastName||''}
        onChange={(e)=>handleChange('lastName',e.target.value)}
        />
        <div className="spacer"/>
      </>}
      <TextField fullWidth size="small"
      label={Data.input.email}
      value={user.email||''}
      onChange={(e)=>handleChange('email',e.target.value)}
      />
    </div>
    <div className="action">
      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
        <Checkbox
        checked={user.terms||false}
        onChange={(e)=>handleChange('terms',e.target.checked)}
        />
        <div className="terms">
          {Data.input.terms}
        </div>
      </Stack>
      <div className="spacer"/>
      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
        <Checkbox
        checked={user.optin||false}
        onChange={(e)=>handleChange('optin',e.target.checked)}
        />
        <div className="optin">
          <div>{Data.input.optin} <span className="more-btn" onClick={()=>setOptinMore(true)}>{Data.input.optinMoreBtn}</span></div>
        </div>
      </Stack>
      <div className="spacer"/>
      {valid.length===0 ? <Link to={`/${city}/universe/`} className="btn">
            {Data.input.nextBtn}
        </Link>
        :
        <div className="btn disabled" onClick={showErrors}>
          {Data.input.nextBtn}
        </div>
      }
    </div>
    <Dialog onClose={()=>setErrors(false)} open={errors}>
      <List>
        {valid.map((err,i)=><ListItem className="error" key={i}>{err}</ListItem>)}
      </List>
    </Dialog>
    <Dialog onClose={()=>setOptinMore(false)} open={optinMore}>
      <List>
        <ListItem className="error">{city === 'la' ? Data.input.optinMoreLa : Data.input.optinMore}</ListItem>
      </List>
    </Dialog>
  </div>;
}

export default Input;
