import React from 'react';
import waterbay from './assets/Waterbay.jpg';
import rooftop from './assets/Rooftop.jpg';
import downtown from './assets/Downtown.jpg';
import basket from './assets/basket.jpg';
import {ReactComponent as Logo} from './assets/logo.svg';

const Data={
  home:{
    logo:<Logo/>,
    text:<>Train like a pro. Get Ready to shoot hoops&nbsp;!</>,
    startBtn:'START THE EXPERIENCE',
  },
  input:{
    country:'Country / Region *',
    zipCode:'ZIPCODE *',
    birthDate:'Date of birth *',
    name:'FIRST NAME *',
    lastName:'LAST NAME *',
    email:'EMAIL ADDRESS *',
    nextBtn:'NEXT',
    terms:<div>I agree to the <a rel="noreferrer" href={'/Official_Rules.pdf'} target="_blank">Official Rules</a>, and the <a rel="noreferrer" href={'/Privacy_Policy.pdf'} target="_blank">Privacy Policy</a>.&nbsp;*</div>,
    optin:<div>I would like to get information about Hennessy, its products, offers, and other related products and information.&nbsp;*</div>,
    optinMore:<div>I understand that Hennessy may share this information with other Moët Hennessy USA brands for their own use. I understand that my information will be used as described here and in the <a rel="noreferrer" href={'/Privacy_Policy.pdf'} target="_blank">Privacy Policy</a>.</div>,
    optinMoreLa:<div>I understand that Hennessy may share this information with other Moët Hennessy USA brands for their own use. I understand that my information will be used as described here and in the <a rel="noreferrer" href="https://www.hennessy.com/en-us/privacy-cookies-notice" target="_blank">Privacy Policy</a>.</div>,
    optinMoreBtn:<>more</>,
    errors:{
      age:(age)=>`You must be over ${age}`,
      email:()=>`E-mail is not valid`,
      country:()=>`You must pick a country`,
      zipCode:()=>`Zipcode must be 5 digits`,
      name:()=>`You must type your first name`,
      lastName:()=>`You must type your last name`,
      terms:()=>`You must agree to the terms and conditions`,
      optin:()=>`You must opt in`,
    },
    legalAge:{
      la:21,
      ist:18,
      ma:18,
      dub:21,
    }
  },
  universe:{
    text:<>Please select a basketball court universe.</>,
    playgrounds:[
      {
        id:1,
        title:'Waterbay',
        visuel:<img alt="playground" src={waterbay}/>
      },
      {
        id:2,
        title:'Rooftop',
        visuel:<img alt="playground" src={rooftop}/>
      },
      {
        id:3,
        title:'Downtown',
        visuel:<img alt="playground" src={downtown}/>
      },
    ],
    nextBtn:'CHOOSE',
  },
  start:{
    drawing:<img alt="playground" src={basket}/>,
    text:<div>Place yourself in the circle, click play and enjoy&nbsp;!</div>,
    playBtn:'PLAY !',
    question:<div>At the end of the game, do you want to take a selfie&nbsp;?</div>,
    questionYes:'Yes',
    questionNo:'No',
  },
  logout:{
    text:<div><p>Your session has expired</p><p>Scan the QR code again</p></div>,
  },
  done:{
    text:<div><p>Your game is over</p><p>To play another one, scan the QR code</p></div>,
  },
  wait:{
    text:'Please wait',
    rankUnitSingular:'player',
    rankUnitPlural:'players',
    rank:'in the line',
  },
  playing:{
    text:<div>Put down your phone and play&nbsp;!</div>,
  },
  notFound:{
    text:'Not found',
  }
}
export default Data;
